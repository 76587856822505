@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

textarea:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}
